import {FileManagerService} from "../services";

export default (formData) => {
    return new Promise((resolve, reject) => {
        FileManagerService.uploadClientFile(formData)
            .then(response => {
                resolve(response)
            })
            .catch(err => reject(err.response.data))
    })

}