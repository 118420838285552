import './AdminAuthIps.scss'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
    Button,
    Col,
    Descriptions,
    Form,
    Icon,
    Input,
    Modal,
    notification,
    Row,
    Select,
    Spin,
    Switch,
    Tooltip
} from 'antd'

import {UsersService} from '../../services'
import {getUserIpPromise, saveParamsPromise} from './promises'
import {getParamsPromise} from '../AdminParams/promises'


const AdminAuthIps = (props) => {
    const {t} = useTranslation()
    const {Option} = Select
    const {currentUser, form, handleUnsavedChanges} = props
    const {getFieldDecorator} = form

    const [usersList, setUsersList] = useState([])
    const [selectedUserId, setSelectedUserId] = useState('')
    const [ipsList, setIpsList] = useState([])
    const [userIpIsLoading, setUserIpIsLoading] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    /*Password params states*/
    const [expirationAllow, setExpirationAllow] = useState("N");
    const [expirationValue, setExpirationValue] = useState(0);
    const [notificationValue, setNotificationValue] = useState(0);
    const [isGlobalPasswordValidation, setIsGlobalPasswordValidation] = useState([]);

    const { confirm } = Modal;

    useEffect(() => {
        handleDefaultUser()
        handleGetUsersList()
        handleGetParams()

    }, [])

    const handleGetParams = async () => {

        const params = await getParamsPromise()
        await setIpsList(params.ips)
        setIsLoading(false)

        const passwordSettings = parseInt(params.passwordSettings.globalPasswordSetting,10) > 0;
        setIsGlobalPasswordValidation(passwordSettings)

        if (passwordSettings) {
            const expirationAllow = params.passwordSettings.expirationAllow === null ? "N" : params.passwordSettings.expirationAllow
            const expirationParam = params.passwordSettings.expirationTime === null ? 0 : params.passwordSettings.expirationTime
            const notificationParam = params.passwordSettings.notificationTime === null ? 0 :  params.passwordSettings.notificationTime

            let clientValidatePwd = (expirationParam && expirationParam[0] !== "0");
            handleSwitchChange(clientValidatePwd, false)
            setExpirationAllow(expirationAllow);
            setExpirationValue(expirationParam);
            setNotificationValue(notificationParam)
        }
    }

    const handleGetUsersList = async () => {
        const u = await UsersService.read()
        setUsersList(u.data.records)
    }

    const handleDefaultUser = async () => {
        if (currentUser.cliente.oficialCto !== null) {
            setSelectedUserId(currentUser.cliente.oficialCto.id)
        }
    }

    const handleUserChange = async (id) => {
        setSelectedUserId(id)
        handleUnsavedChanges(true);
    }

    const handleAddIp = async (e) => {
        let ip = document.getElementsByClassName('user-ip')[0].value

        if (ip !== '') {
            setIpsList(oldIpsList => [...oldIpsList, ip])
            form.setFieldsValue({userIp: ''})
            handleUnsavedChanges(true);
        }
    }

    const handleRemoveIp = async (index) => {
        const newIpsList = ipsList.slice()
        newIpsList.splice(index, 1)
        setIpsList(newIpsList)
        handleUnsavedChanges(true);
    }

    const handleGetUserIp = async () => {
        setUserIpIsLoading(true)
        const userIp = await getUserIpPromise()
        await form.setFieldsValue({ userIp })
        setUserIpIsLoading(false)
    }

    const isNumeric = (value) => {
        return /^[0-9]*$/.test(value);
    };

    const handleSaveParams = async () => {

        // Validación adicional para expirationValue
        if (expirationValue === 0 && expirationAllow !== "N") {
            confirm({
                title: t("messages.aml.params.confirmation.title"),
                content: t("messages.aml.params.zero.expiration"),
                okType: 'primary',
                okText: t("messages.aml.si"),
                cancelText: t("messages.aml.no"),
                onOk() {
                    setExpirationValue(0)
                    setExpirationAllow("N");
                    }
            });
            return;

        }else {
            await setIsSaving(true)
            confirm({
                title: t("messages.aml.params.confirmation.title"),
                content: t("messages.aml.params.confirmation.message"),
                okType: 'primary',
                okText: t("messages.aml.si") + ", guardar cambios",
                cancelText: t("messages.aml.no"),
                onOk() {
                    const params = {}
                    params.ipsList = ipsList
                    if (isGlobalPasswordValidation) {
                        if (expirationAllow === "Y" && (!isNumeric(expirationValue) || !isNumeric(notificationValue))) {
                            notification.error({
                                message: t('messages.aml.params.error.input.message'),
                                description: t('messages.aml.valid.values')
                            })
                            setIsSaving(false)
                            return;
                        }

                        params.expirationAllow = expirationAllow
                        params.expirationPwd = expirationValue
                        params.notificationPwd = notificationValue
                    }

                    params.ofCto = selectedUserId;
                    saveParams(params);
                },
                onCancel() {
                    setIsSaving(false)
                }
            });
        }
    }

    const saveParams = async (params) => {
        await saveParamsPromise(params).then((response) =>{
            if(response.success){
                if(response.data === "OK"){
                    handleUnsavedChanges(false)
                    notification.success({
                        message: t('messages.aml.notifications.succesfulOperation'),
                        description: 'Actualizado exitosamente.'
                    })
                }else{
                    notification.error({
                        message: t('messages.aml.notifications.anErrorOcurred'),
                        description: t('Error al guardar datos.')
                    })
                }
            }
        });
        setIsSaving(false)
    }

    const handleSwitchChange = (checked, userChange) => {
        if(userChange) {
            handleUnsavedChanges(true);
        }
        setExpirationAllow(checked ? "Y" : "N");
    };

    const handleExpirationInputChange = (e) => {
        let currentValue = e.target.value.trim().replace(/\D/g, '');
        let newValue = parseInt(currentValue, 10);

        if (isNaN(newValue) || newValue < 0) {
            newValue = 0;
        } else if (newValue > 9999) {
            newValue = 9999;
        }
        setExpirationValue(newValue)
        handleUnsavedChanges(true);
    };

    const handleNotificationInputChange = (e) => {
        let currentValue = e.target.value.trim().replace(/\D/g, '');
        let newValue = parseInt(currentValue, 10);

        if (isNaN(newValue) || newValue < 0) {
            newValue = 0;
        } else if (newValue > 9999) {
            newValue = 9999;
        }
        setNotificationValue(newValue)
        handleUnsavedChanges(true);
    };


    return (

        <div className="admin-auth-ips block">
            <br/>

            {!isLoading ?
                <>
                    <div className="admin-users">
                        <div className="tools-area">
                            {currentUser.type !== 'AUDIT' &&
                                <Button className="send-button"
                                        icon={isSaving ? 'loading' : 'check'}
                                        type="primary"
                                        onClick={handleSaveParams}>
                                    Guardar cambios
                                </Button>
                            }
                        </div>
                    </div>

                    { isGlobalPasswordValidation &&  currentUser.type === 'SADMIN' &&
                        <Descriptions column={1} layout="horizontal" bordered>
                            <Descriptions.Item label={<b>{t("messages.aml.params.password.control")}</b>}>
                                     <Row>
                                        <Col span={9}>
                                            <label> {t("messages.aml.params.password.expiration.time")} </label>
                                        </Col>
                                        <Col span={11}>
                                            <Input className='expiration_input'
                                                   style={{textAlign: 'center', fontWeight: 'bold', color: 'back'}}
                                                   placeholder={expirationValue}
                                                   value={expirationValue}
                                                   disabled={expirationAllow !== "Y"}
                                                   type={"number"}
                                                   onChange={handleExpirationInputChange}
                                            />
                                        </Col>
                                        <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Tooltip title={t("messages.aml.params.password.Disable")}>
                                            <Switch
                                                className='expiration_switch'
                                                checked={expirationAllow === "Y"}
                                                onChange={(e) => {handleSwitchChange(e, true)}}
                                            />
                                        </Tooltip>
                                        </Col>
                                    </Row>
                                <br/>
                                     <Col span={9}>
                                        <label> {t("messages.aml.params.password.notification.days")} </label>
                                    </Col>
                                    <Col span={11}>
                                        <Input
                                            className='notification_input'
                                            style={{textAlign: 'center', fontWeight: 'bold'}}
                                            placeholder={notificationValue}
                                            value={notificationValue}
                                            type={"number"}
                                            disabled={expirationAllow !== "Y"}
                                            onChange={handleNotificationInputChange}
                                        />
                                    </Col>

                            </Descriptions.Item>
                        </Descriptions>
                    }
                    {currentUser.cliente.modules.includes('OFCTO') &&
                        <Descriptions layout="horizontal" bordered>
                            <Descriptions.Item
                                label={<b>{t("messages.aml.modulesNames.complianceOfficer")}</b>}>
                                    <Select value={selectedUserId}
                                            style={{width: '84%',}}
                                            placeholder={t('messages.aml.select')}
                                            onChange={handleUserChange}>
                                        {usersList.map(user => (user.type === 'SADMIN' || user.type === 'ADMIN' || user.type === 'USUARIO') &&
                                            <Option value={user.id}>{user.name}</Option>)
                                        }
                                    </Select>
                            </Descriptions.Item>
                        </Descriptions>
                    }

                    <Descriptions column={1} layout="horizontal" bordered>
                        <Descriptions.Item label={<b>{t('messages.aml.authorizedIps')}</b>}>
                                <div className="authorized-ips block">
                                    <div className="authorized-ips-inner">
                                        <div className="authorized-ips-header">
                                                <Col span={21}>
                                                {getFieldDecorator('userIp')(
                                                <Input className="user-ip" placeholder={t('messages.aml.enterIpAddress')}/>)}
                                                </Col>
                                                <Col span={1}>
                                                <div className="add-button" onClick={handleAddIp}>
                                                <Icon type="plus"/>
                                                </div>
                                                </Col>

                                                <Col span={1}>
                                                <div className="get-ip-button" onClick={handleGetUserIp}><Icon
                                                type={userIpIsLoading ? 'loading' : 'environment'}/>
                                                </div>
                                                </Col>
                                        </div>
                                        <ul>
                                            {ipsList.map((ip, index) =>
                                                <li key={index}>
                                                    <span className="ip-address">{ip}</span>
                                                    <span className="remove"
                                                          onClick={() => handleRemoveIp(index)}><Icon
                                                          type="close"/>
                                                    </span>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                        </Descriptions.Item>
                    </Descriptions>
                </>
                :
                <Spin spinning={true} size="large"/>
            }

        </div>
    )
}

export default Form.create()(AdminAuthIps)
