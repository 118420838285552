import './Admin.scss'
import React, {Component} from 'react'
import { withTranslation } from 'react-i18next'
import {Modal, Tabs} from 'antd'
import { Page, PageBottomBar, PageContent, PageFooter, PageHeader, PageTopBar } from '../../layouts/Private/components'

import {
  AdminUsersPage,
  AdminAuditPage,
  AdminSearchPage,
  AdminConsultasPage,
  AdminLogBasesPage,
  AdminListsPage,
  AdminAuthIpsPage,
  AdminRiskPage,
  AdminGroupsPage, AdminTransFilePage
} from '../'

const { TabPane } = Tabs

class Admin extends Component {
  state = {
    breadcrumbs: this.getBreadcrumbs(),
    activeTabKey: "1",
    nextTabKey: null,
    isConfirmVisible: false,
    formHasChanges: false
  }

  getBreadcrumbs() {
    const { t } = this.props

    const breadcrumbs = [
      {
        title: t('messages.aml.administration'),
        icon: 'file-search',
        link: '/administracion'
      },
    ]

    return breadcrumbs
  }

  //manejo comportamiento cambio por pestaña
  handleTabChange = (currentKey) => {
    const { activeTabKey, formHasChanges} = this.state
    if (activeTabKey === "9" && currentKey !== "9" && formHasChanges) {
      this.setState({nextTabKey: currentKey})
      this.setState({isConfirmVisible: true})

    }else{
      this.setState({activeTabKey: currentKey});
    }
  };
  handleConfirmChange = () => {
    const{nextTabKey} = this.state
    this.handleUnsavedChanges(false)
    this.setState({isConfirmVisible: false})
    this.setState({activeTabKey: nextTabKey});


  };
  handleCancelChange = () => {
    this.setState({isConfirmVisible: false});
    this.setState({nextTabKey: null});
  };

  handleUnsavedChanges = (value) => {
    const hasChanges = value === true ? value : false
      this.setState({formHasChanges: hasChanges })
  }

  render() {
    const { breadcrumbs , activeTabKey, isConfirmVisible, formHasChanges} = this.state
    const { currentUser, t } = this.props

    return (
      <div className="admin">
        <PageTopBar breadcrumbs={ breadcrumbs } />
        <Page>
          <PageHeader
            title={ t('messages.aml.administrationPageTitle') }
            description={ t('messages.aml.administrationPageDescription')}
            icon="file-search"
            />
          <PageContent>
            <Tabs
                defaultActiveKey={"1"}
                type="card"
                activeKey={activeTabKey}
                onChange={this.handleTabChange}
            >

              { !currentUser.cliente.modules.includes('CONSULTA2') &&
              <TabPane tab={ t('messages.aml.users') } key="1" onChange={this.handleTabChange.bind(this)} >
                <AdminUsersPage currentUser={ currentUser } />
              </TabPane>
              }
              <TabPane tab={ t('messages.aml.auditTitle') } key="2">
                <AdminAuditPage currentUser={ currentUser } />
              </TabPane>
              { (currentUser.cliente.planBatch !== null || currentUser.cliente.planHist !== null) &&
                <TabPane tab={ t('messages.aml.searches') } key="3">
                  <AdminSearchPage currentUser={ currentUser } />
                </TabPane>
              }
              { currentUser.type === 'SADMIN' && (currentUser.cliente.planBatch !== null || currentUser.cliente.planHist !== null) &&
                <TabPane tab={ t("messages.aml.admin.query") } key="4">
                  <AdminConsultasPage currentUser={ currentUser } />
                </TabPane>
              }
              { currentUser.type === 'SADMIN' && currentUser.cliente.modules.includes('PFA') &&
                <TabPane tab={ t("messages.aml.admin.log") } key="5">
                  <AdminLogBasesPage currentUser={ currentUser }/>
                </TabPane>
              }

              { currentUser.cliente.modules.includes('NEG') && (currentUser.type === 'SADMIN' || (currentUser.modules && currentUser.modules.includes('LOADNEG'))) &&
                <TabPane tab={ t('messages.aml.ownLists') } key="6">
                  <AdminListsPage currentUser={ currentUser } />
                </TabPane>
              }
              { currentUser.type === 'SADMIN' &&
                <TabPane tab={ t('messages.aml.risk') } key="7">
                  <AdminRiskPage currentUser={ currentUser} />
                </TabPane>
              }
              { currentUser.type === 'SADMIN' && currentUser.cliente.modules.includes('REGISTRO') &&
                <TabPane tab={ t('messages.aml.groups') } key="8">
                  <AdminGroupsPage currentUser={ currentUser } />
                </TabPane>
              }
              { currentUser.type === 'SADMIN' &&
                <TabPane tab={ t('messages.aml.parameters') } key="9">
                  <AdminAuthIpsPage currentUser={ currentUser } handleUnsavedChanges={ this.handleUnsavedChanges.bind(this)} />
                </TabPane>
              }
              { (currentUser.type === 'ADMIN' || currentUser.type === 'SADMIN')
                  && currentUser.cliente.modules.includes('TRANS_FILES')
                  && currentUser.modules && currentUser.modules.includes('USER_TRANS_FILE') &&
                  <TabPane tab={ t('messages.aml.file.transfer') } key="10">
                    <AdminTransFilePage currentUser={ currentUser } />
                  </TabPane>
              }
            </Tabs>

            {formHasChanges && <Modal
                title="Confirmación"
                visible={isConfirmVisible}
                onOk={this.handleConfirmChange.bind(this)}
                onCancel={this.handleCancelChange.bind(this)}
                okText="Salir sin guardar"
                cancelText="Continuar"
            >
              <p>  Tienes cambios sin guardar. Si continúas, los cambios realizados se perderán. ¿Estás seguro de que deseas continuar? </p>
            </Modal>}

          </PageContent>
          <PageFooter>

          </PageFooter>
        </Page>
        <PageBottomBar breadcrumbs={ breadcrumbs } />
      </div>
    )
  }
}

export default withTranslation()(Admin)
