import './Login.scss'
import React, {Component} from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { Button, Col, Form, Icon, Input, notification, Row, Spin } from 'antd'
import {changePasswordPromise, getAuthTokenPromise} from '../../promises'
import { resetPasswordPromise } from './promises'
import { authTokenSessionStorageSaverHelper } from '../../helpers'
import {Captcha, ModalChangePassword} from '../../layouts/Private/components'
import Logo from '../../layouts/commonComponent/components/Logo/Logo'
import { decodeAuthToken} from '../../helpers/decodeAuthToken'
import {LocalStorageService} from '../../services'


class Login extends Component {
  state = {
    username: '',
    password: '',
    isLoading: false,
    isRestore: false,
    captchaResponse: null,
    captchaSuccess: false,
    captchaKey: Math.random(),
    isModalChangePasswordVisible: false,
  }

  async handleOpenModalChangePassword() {
    this.setState({isModalChangePasswordVisible: true})
  }

  async handleCloseModalChangePassword() {
    this.setState({isModalChangePasswordVisible: false})
  }

  async handleSaveChangePassword(passwordCurrent, passwordNew, passwordNewConfirm) {
    await changePasswordPromise(passwordCurrent, passwordNew, passwordNewConfirm)

    this.setState({isModalChangePasswordVisible: false})
  }

  handleUsernameOnChange(username) {
    this.setState({ username })
  }

  handlePasswordOnChange(password) {
    this.setState({ password })
  }

  validateFields(fields) {
    const { form } = this.props

    return form.validateFields(fields, { force: true })
  }

  async handleSubmit(e) {
    e.preventDefault()

    await this.validateFields(['username', 'password'])

    this.setState({ isLoading: true })

    const { username, password } = this.state
    const { client } = this.props
    let loginUser = username
    if(client?.id) {
      loginUser = loginUser + '@' + client.id
    }
    
    const authToken = await getAuthTokenPromise(loginUser, password)

    this.setState({ isLoading: false })

    if (!authToken.error) {
      if (authToken && authToken.token) {
        await authTokenSessionStorageSaverHelper(authToken)

        const decodedToken = decodeAuthToken(authToken.token);
        LocalStorageService.create("userType",decodedToken.type);
        LocalStorageService.create("isPasswordBlocked",decodedToken.isPasswordBlocked);

        const {successHandler} = this.props
        await successHandler()
      }
    }
  }

  async handleCatpcha (success, response) {
    this.setState({captchaSuccess: success})
		if(response){
      this.setState({captchaResponse: response})
		}else{
      this.setState({captchaResponse: null})
      this.setState({captchaSuccess: false})
		}
	}

  renderFormItem = (formItem) => {
    const { getFieldDecorator } = this.props.form

    return (
      <Form.Item label={ formItem.label }>
        { getFieldDecorator(formItem.name, { rules: formItem.rules })(formItem.item) }
      </Form.Item>
    )
  }

  async handleSwitchToRestore(e) {
    e.preventDefault()

    await this.setState({ isRestore: true })
  }

  async handleSwitchToLogin(e) {
    e.preventDefault()

    await this.setState({ isRestore: false })
  }

  async handleRestorePassword(e) {
    e.preventDefault()

    const { t, client } = this.props

    this.handleCatpcha(false)
    this.setState({ captchaKey: Math.random() })
    const { username, captchaResponse } = this.state

    if(!captchaResponse) {
      notification.error({
        placement: 'topLeft',
        message: "Error",
        description: "Validacion no Superada"
      })
    }else {
      let loginUser = username
      if(client?.abreviado) {
        loginUser = loginUser + '@' + client.abreviado
      }

      this.validateFields(['username'])
      this.setState({ isLoading: true })

      const reset = await resetPasswordPromise(loginUser, captchaResponse)

      if (reset.success) {
        notification.success({
          message: t('messages.aml.successfulOperation'),
          description: t('messages.aml.checkYourEmail')
        })

        window.setTimeout(async () => {
          await this.setState({ isRestore: false })
        }, 4500)
      } else {
        notification.error({
          message: t('messages.aml.notifications.anErrorOcurred'),
          description: t('messages.aml.usernameDoesNotExists')
        })
      }
    }

    this.setState({ isLoading: false })
  }

  render() {
    const { t, client } = this.props
    const { isLoading, isRestore, captchaKey, captchaSuccess, isModalChangePasswordVisible } = this.state

    return (
      <div className="login">
        <div className="login-header">
          <Row>
            <Col xs={ 9 }>
              { client?.id &&
                <Logo currentUser={{clientId: client.id}} isForm={false}/>
              }
            </Col>
            <Col xs={ 6 }>
              <div className="spin-wrapper">
                <Spin style={{ fontColor: '#fff' }} spinning={ isLoading } size={ 'large' } />
              </div>
            </Col>
            <Col xs={ 9 }>
            </Col>
          </Row>
        </div>
        <div className="login-content">
          <Row>
            <Col xs={3} sm={5} md={7} lg={8} xl={9}>
            </Col>
            <Col xs={18} sm={14} md={10} lg={8} xl={6}>
              <div className="login-box">
                <Form onSubmit={ isRestore ? this.handleRestorePassword.bind(this) : this.handleSubmit.bind(this) } className="login-form">
                  <Row>
                    <Col xs={ 24 }>
                      <h2>{ isRestore ? t('messages.aml.restorePassword') : 'AMLupdate v.2.0' }</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={ 24 }>
                      {
                        this.renderFormItem({
                          name: 'username',
                          rules: [{ required: true, message: t('messages.aml.dontForgetUsername') }],
                          item: (
                            <Input
                              disabled={ false }
                              onChange={ (e) => this.handleUsernameOnChange.bind(this)(e.target.value) }
                              prefix={ <Icon type="user" style={{ color: 'rgba(0,0,0,.2)' }} /> }
                              placeholder={ t('messages.aml.username') }
                              />
                          )
                        })
                      }
                    </Col>
                  </Row>
                  { !isRestore ?
                    <Row>
                      <Col xs={ 24 }>
                        {
                          this.renderFormItem({
                            name: 'password',
                            rules: [{ required: true, message: t('messages.aml.dontForgetPassword') }],
                            item: (
                              <Input
                                onChange={ (e) => this.handlePasswordOnChange.bind(this)(e.target.value) }
                                type="password"
                                autoComplete="off"
                                prefix={ <Icon type="lock" style={{ color: 'rgba(0,0,0,.2)' }} /> }
                                placeholder={ t('messages.aml.password') }
                                />
                            )
                          })
                        }
                      </Col>
                    </Row>
                    :
                    <Row className="captchaVerify" style={{padding:'10px'}}>
											<Captcha success={this.handleCatpcha.bind(this)} key={captchaKey}/>
										</Row>
                  }
                  { (!isRestore || captchaSuccess) &&
                  <Row>
                    <Col xs={ 24 }>
                      <Button className="login-form-button" type="primary" htmlType="submit">{ t('messages.aml.send') }</Button>
                    </Col>
                  </Row>
                  }
                </Form>
              </div>
              { !isRestore && <a href='#' className="login-link" onClick={ this.handleSwitchToRestore.bind(this) } style={{ display: 'block', textAlign: 'center', paddingTop: 15 }}>{ t('messages.aml.forgotYourPassword') }</a> }
              { isRestore && <a href='#' className="login-link" onClick={ this.handleSwitchToLogin.bind(this) } style={{ display: 'block', textAlign: 'center', paddingTop: 15 }}>{ t('messages.aml.backToLogin') }</a> }
            </Col>
            <Col xs={3} sm={5} md={7} lg={8} xl={9}>
            </Col>
          </Row>
        </div>
        {isModalChangePasswordVisible &&
            <ModalChangePassword
                visible={true}
                onOk={this.handleSaveChangePassword.bind(this)}
                onCancel={this.handleCloseModalChangePassword.bind(this)}
                isForced
            />
        }
      </div>
    )
  }
}

const LoginForm = Form.create({ name: 'login_form' })(Login)


export default withTranslation()(withRouter(LoginForm))
