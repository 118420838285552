import './AdminTransFile.scss';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Form, Icon, message, Modal, notification, Spin} from 'antd';
import 'devextreme/dist/css/dx.light.css';
import FileManager, {Column, Details, Item, ItemView, Permissions, Toolbar} from 'devextreme-react/file-manager';
import {
    createFolderPathPromise,
    deleteClientFileListPromise,
    getClientFileListPromise,
    moveClientFilePromise,
    uploadClientFilePromise
} from "../../promises";
import Dragger from "antd/es/upload/Dragger";
import uploadIcon from "../Batch/components/ModalAddBatchFile/drag-icon.png";
import {useTranslation} from "react-i18next";
import image from "./assets/image.png";
import rar from "./assets/rar.png";
import pdf from "./assets/pdf.png";
import word from "./assets/word.png";
import ppt from "./assets/ppt.png";
import excel from "./assets/excel.png";
import file from "./assets/file.png";
import folder from "./assets/folder.png";
import {LocalStorageService, ReportService} from "../../services";

import esMessages from './locales/es.json';
import enMessages from './locales/en.json';

import {loadMessages, locale} from 'devextreme/localization';
import {NewFolderModal} from "./components/index";

const AdminTransFile = (props) => {

    //Hace referencia al componente FileManager
    const fileManagerRef = useRef(null);
    const {t} = useTranslation();

    const {currentUser} = props
    const {form} = props;

    const [filesAndFolderList, setFilesAndFolderList] = useState([]);
    const [isLoadFileList, setIsLoadFileList] = useState(false);
    const [isModalUploadRelatedVisible, setIsModalUploadRelatedVisible] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [fileListUpload, setFileListUpload] = useState([]);
    const [unableToDeleteFolders, setUnableToDeleteFolders] = useState([]);
    const [statusSelection, setStatusSelection] = useState({});
    const [isNewFolderOpen, setIsNewFolderOpen] = useState(false);
    const [isCreateFolderLoading, setIsCreateFolderLoading] = useState(false);


    useEffect(() => {
        const language = LocalStorageService.read('i18nextLng')
        locale(language.substring(0, 2))



        const localeMessage = language.substring(0, 2) === "es" ? esMessages : enMessages;
        loadMessages(localeMessage);
        loadDataTable();
    }, []);

    //Carga contenido de tabla
    const loadDataTable = useCallback(() => {
        getClientFileListPromise().then((response) => {
            const filesAndFolder = itemDataFormater(response)
            if(filesAndFolder.items !== undefined) {
                setFilesAndFolderList(filesAndFolder.items[0].items);
            }
        })
            .finally(() => {
                setIsLoadFileList(true);
            });
    }, []);

    const itemDataFormater = (data) => {
        const levelData = {};

        for (const item of data) {
            const pathParts = item.path.split('/').filter(Boolean);
            let currentLevel = levelData;

            //valida cada parte del path de current item
            for (const part of pathParts) {
                currentLevel.items = currentLevel.items || [];
                const existingItem = currentLevel.items.find((i) => i.name === part);

                //casos con items o hijos
                if (existingItem) {
                    currentLevel = existingItem;
                } else {
                    const newItem = {
                        name: part,
                        isDirectory: item.isFolder,
                    };
                    if (item.size !== undefined) newItem.size = item.size;
                    if (item.category) newItem.category = item.category;
                    currentLevel.items.push(newItem);
                    currentLevel = newItem;
                }
            }
        }

        //validar
        const flattenTree = (node) => {

            const item = {
                name: node.name,
                isDirectory: node.isDirectory,
                type: node.isDirectory ? 'folder' : 'file',
            };
            if (node.size !== undefined) item.size = node.size;
            if (node.category) item.category = node.category;
            if (node.items) {
                item.items = node.items.map((subNode) => flattenTree(subNode));
            }

            return item;
        };
        return flattenTree(levelData);
    }


    //Función agregar archivo
    const handleUploadFile = () => {
        setIsModalUploadRelatedVisible(true)
    }
    const newFileMenuOptions = {
        items: [
            {
                text: t('messages.aml.file.manager.add.file'),
                icon: 'plus'
            },
        ],
        onItemClick: handleUploadFile
    };
    const handleActionSaveClientFile = () => {
        const formData = new FormData()
        fileListUpload.forEach(file => {
            formData.append('file', file)
            formData.append('targetFolder', getCurrentFolder())
        })
        setUploading(true)
        uploadClientFilePromise(formData)
            .then((response) => {
                if (response.status === 200) {
                    notification.success({
                        message: t('messages.aml.information'),
                        description: response.data
                    });
                    setIsModalUploadRelatedVisible(false);
                    loadDataTable();
                } else {
                    notification.error({
                        message: 'Error',
                        description: response.data
                    });
                }
            })
            .catch((e) => {
                const errorMensaje= e.toString() || 'Ocurrió un error desconocido.';
                notification.error({
                    message: 'Error',
                    description: errorMensaje
                })
            })
            .finally(() => {
                setUploaded(false)
                setUploading(false)
                setFileListUpload([])
            })
    }
    const propsUpload = {
        //accept: ".*",//
        onRemove: file => {
            const index = fileListUpload.indexOf(file)
            const newFileList = fileListUpload.slice()
            newFileList.splice(index, 1)
            setUploaded(false)
            return {
                fileListUpload: newFileList
            }
        },
        beforeUpload: file => {
            if (fileListUpload.length === 0) {
                setFileListUpload(fileListUpload => [...fileListUpload, file])
            }
            setUploaded(true)
            return false
        },
        multiple: false,
        fileList: fileListUpload,
    }
    const cleanFile = () => {
        setFileListUpload([])
        setUploaded(false)
        setUploading(false)
    }


    //Crear carpeta
    function handleCreateFolder(folderName) {
        setIsCreateFolderLoading(true)
        const currentFolderPath = getCurrentFolder()

        if (folderName === null || folderName === "") {
            notification['error']({
                "message": t('messages.aml.information'),
                "description": t('messages.aml.file.manager.error.new.folder.create.message')
            });
            return;
        }

        let completeFolderPath = currentFolderPath + folderName.replace(":", "")

        createFolderPathPromise(completeFolderPath)
            .then(response => {
                notification['success']({
                    "message": t('messages.aml.information'),
                    "description": t('messages.aml.file.manager.success.new.folder.create.message')
                })
                setIsModalUploadRelatedVisible(false)

                loadDataTable()
            })
            .catch((e) => {
                notification.error({
                    message: 'Error',
                    description: e
                })
            })
            .finally(() => {
                setUploaded(false)
                setUploading(false)
                setFileListUpload([])
                setIsNewFolderOpen(false)
                setIsCreateFolderLoading(false)
            })

    }

    const newFolderMenuOptions = {
        items: [
            {
                text: t('messages.aml.file.manager.add.folder'),
                icon: 'plus'
            },
        ],
        onItemClick: () => {
            setIsNewFolderOpen(true)
            //folderNameRef.current.focus()
        }
    };

    //Botón eliminar
    const handleDeleteFiles = () => {
        const selectedItems = fileManagerRef.current._instance.getSelectedItems()
            .map(item => {
                return {
                    path: item.path,
                    isFolder: item.isDirectory,
                }
            });

        deleteClientFileListPromise(selectedItems)
            .then(response => {
                if (response.status === 'OK') {
                    if (response.unableToDeleteFolders && response.unableToDeleteFolders.length > 0) {
                        const folderNames = response.unableToDeleteFolders.map(folder => folder);
                        setUnableToDeleteFolders(folderNames)
                        //alert("Las siguientes carpetas poseen contenido en su interior, no es posible eliminar:  \n" + folderNames.join("\n ") );

                    }else{
                        notification['success']({
                            "message": t('messages.aml.information'),
                            "description": t('messages.aml.file.manager.success.delete.message')
                        })
                    }
                }else{
                    notification['error']({
                        "message": 'Error',
                        "description": t('messages.aml.file.manager.error.delete.elemets')
                    })
                }
                loadDataTable();
            })
            .catch((error) => {
                console.error('Error:', error);
                Modal.error({
                    title: 'Error',
                    content: (
                        <div>
                            <p>Error al eliminar.</p>
                        </div>
                    ),
                });
            });
    };

    //Descarga de archivos
    const handleDownloadFile = async (e) => {

        //Librería file_manager asume la descarga como error, pop up de error, al activar notificaciones
        //cancela la descarga
        e.cancel = true;

        const fileName = fileManagerRef.current._instance.getSelectedItems()[0].name

        message.loading({content: 'Descargando archivo...', duration: 0, key: 'msg-report'})
        await ReportService.read('/fileManager/downloadClientFile/' + fileName, {"currentPath": getCurrentFolder()}, null, fileName, {
            onDownloadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                if (progressEvent.total > 0) {
                    message.loading({
                        content: 'Descargando documento...' + percentCompleted + '%',
                        duration: 0,
                        key: 'msg-report'
                    })
                }
            }
        })
        message.destroy()
    }

    //Botón Mover
    const onItemMoved = (e) => {
        const filePath = getCurrentFolder() + e.itemName;
        let folderPath =  getCurrentFolder().split('/')[0] + "/" + e.itemPath.replace(e.itemName, "")

        moveFileToFolder(filePath, folderPath);
    };
    const moveFileToFolder = (filePath, destinationPath) => {


        moveClientFilePromise(filePath, destinationPath)
            .then(response => {
                if (response.status === 'OK') {
                    notification['success']({
                        "message": t("messages.aml.information"),
                        "description": t('messages.aml.file.manager.success.move.item.message')
                    })
                    loadDataTable();
                }else{
                    notification['error']({
                        "message": 'Error',
                        "description": t('messages.aml.file.manager.move.message')
                    })
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                Modal.error({
                    title: 'Error',
                    content: (
                        <div>
                            <p>Error al mover.</p>
                        </div>
                    ),
                });
            });
    };

    //Lógica selector
    const handleSelectionChanged = (e) => {
        const selectedItems = e.component.getSelectedItems();

        let isFolderSelected = false;
        let countSelectedItem = 0;

        selectedItems.map((item) => {
            if (item.isDirectory) {
                isFolderSelected = true;
            } else {
                countSelectedItem++;
            }
        })

        setStatusSelection({
            "isFolderSelected": isFolderSelected,
            "countSelectedItem": countSelectedItem
        })
    };

    //Logica modales
    const handleOnCancelModalUploadClientFile = () => {
        setIsModalUploadRelatedVisible(false)
        setUploading(false)
        setUploaded(false)
        setFileListUpload([])
    }
    const handleCloseErrorOnDelete = () => {
        setUnableToDeleteFolders([])
        loadDataTable()
    }


    //Función para formatear data proveniente desde backend
    function getCurrentFolder() {
        // Obtiene información desde la librería
        const breadcrumb = fileManagerRef?.current?._instance?._breadcrumbs?._currentDirectory?.fileItem?.relativeName;
        return breadcrumb
            ? `${currentUser?.cliente?.abreviado}/${breadcrumb}/`
            : `${currentUser?.cliente?.abreviado}/`;
    }

    const getIconForFileName = (item) => {
        const itemName = item.name ? item.name : item;

        const itemNameSplit = itemName.split('.')
        if (itemNameSplit.length === 1) {
            return folder
        }

        const extension = itemNameSplit.pop();
        switch (extension.toLowerCase()) {
            case 'png':
            case 'jpeg':
            case 'gif':
            case 'jpg':
                return image;
            case '7zip':
            case 'gz':
            case 'zip':
            case 'rar':
                return rar;
            case 'pdf':
                return pdf;
            case 'doc':
            case 'docx':
                return word;
            case 'ppt':
            case 'pptx':
                return ppt;
            case 'xls':
            case 'xlsx':
                return excel;
            default:
                return file;
        }
    };


    //ordenamiento
    const sortItemsByName = (a, b) => {
        if (a.isDirectory && !b.isDirectory) {
            return -1;
        } else if (!a.isDirectory && b.isDirectory) {
            return 1;
        } else {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            return nameA.localeCompare(nameB);
        }
    };

    return (
        <div className={"transfer-file"}>
            {!isLoadFileList ?
                <Spin className="file-manager-content" spinning={true} size="large"/>
                :
                <div>
                    <FileManager
                        ref={fileManagerRef} //refresh
                        fileSystemProvider={{
                            data: filesAndFolderList,
                            sort: sortItemsByName
                        }}
                        height={450}
                        customizeThumbnail={getIconForFileName}
                        onItemDeleting={handleDeleteFiles}
                        onSelectionChanged={handleSelectionChanged}
                        onItemDownloading={handleDownloadFile}
                        onItemMoved={onItemMoved}

                        notifications={{
                            showPanel: false,
                            showPopup: false
                        }}
                    >

                        <Permissions
                            create={{
                                text: "text",
                                visible: true
                            }}
                            delete={true}
                            download={statusSelection.countSelectedItem === 1 && !statusSelection.isFolderSelected}
                            move={statusSelection.countSelectedItem >= 1 && !statusSelection.isFolderSelected}
                        >
                        </Permissions>


                        <ItemView showParentFolder={false}>
                            <Details>
                                <Column dataField="thumbnail"></Column>
                                <Column dataField="name"></Column>
                                <Column dataField="dateModified"></Column>
                                <Column dataField="size"></Column>
                            </Details>
                        </ItemView>

                        <Toolbar>
                            <Item name="showNavPane" visible={false}/>
                            <Item name="switchView" location="before"/>
                            <Item name="separator"/>
                            <Item widget="dxMenu" options={newFolderMenuOptions}/>
                            <Item widget="dxMenu"/>
                            <Item name="separator" location="after"/>

                            <Item widget="dxMenu" location="after" options={newFileMenuOptions}/>
                            <Item name="refresh"/>

                        </Toolbar>
                    </FileManager>


                    {isModalUploadRelatedVisible &&
                        <Modal
                            title={t('messages.aml.uploadFile')}
                            className="modal-cliente"
                            visible={isModalUploadRelatedVisible}
                            onCancel={handleOnCancelModalUploadClientFile}
                            footer={[
                                <div>
                                    <Button
                                        onClick={handleOnCancelModalUploadClientFile}>{t('messages.aml.cancel')} </Button>
                                    <Button type="primary" onClick={handleActionSaveClientFile} disabled={!uploaded}
                                            loading={uploading}>{t('messages.aml.save')}</Button>
                                </div>
                            ]}>
                            <div className="fix-content-modal">
                            <Form layout="vertical">
                                {fileListUpload.length === 0 ?
                                    <Form.Item>
                                        <Dragger {...propsUpload} >
                                            <img src={uploadIcon} alt="" style={{height: '100px'}}/>
                                            <p className="ant-upload-text">Haga click o arrastre un archivo hacia
                                                aquí.</p>
                                        </Dragger>
                                    </Form.Item>
                                    :
                                    <div className="file-wrapper">
                                        <div className="file-inner">
                                            <div className="image-wrapper">
                                                {!uploading &&
                                                    <div className="remove">
                                                        <Icon type="close" onClick={cleanFile}/>
                                                    </div>
                                                }
                                                <img src={getIconForFileName(fileListUpload[0].name)} alt=""/>
                                            </div>
                                            <p className="file-name">{fileListUpload[0].name}</p>
                                        </div>
                                    </div>
                                }
                            </Form>
                            </div>
                        </Modal>
                    }

                    <Modal
                        title={t('messages.aml.file.manager.error.delete.elemets')}
                        visible={unableToDeleteFolders.length > 0}
                        footer={
                            <Button key="submit" type="primary" onClick={handleCloseErrorOnDelete}>
                                Ok
                            </Button>
                        }
                    >
                        <div className="fix-content-modal">
                            <p>{t('messages.aml.file.manager.folder.content')}</p>
                            <ul>
                                {unableToDeleteFolders.map((folder, index) => (
                                    <li key={index}>{folder}</li>
                                ))}
                            </ul>
                        </div>
                    </Modal>


                    {isNewFolderOpen &&
                        <NewFolderModal
                            isNewFolderOpen={isNewFolderOpen}
                            setIsNewFolderOpen={setIsNewFolderOpen}
                            isCreateFolderLoading={isCreateFolderLoading}
                            setIsCreateFolderLoading={setIsCreateFolderLoading}
                            createFolder={handleCreateFolder}
                            currentFolder={getCurrentFolder()}
                        />
                    }

                </div>
            }
        </div>
    )
}


export default Form.create()(AdminTransFile);
